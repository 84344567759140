import { combineReducers } from "redux";

import UserState from "./User";
import PicState from "./Profile_pic";
import PicCustState from "./Profile._pic_cust";
import RecievrId from "./RecieverId";
import FreelancerId from "./FreelancerId";

const RootReducer = combineReducers({
  UserState,
  PicState,
  PicCustState,
  RecievrId,
  FreelancerId,
});

export default RootReducer;
