import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/login",
    component: lazy(() => import("../pages/authentication/Login")),
  },
  {
    exact: true,
    path: "/auth/google",
    component: lazy(() =>
      import("../pages/authentication/Login/GoogleCallback")
    ),
  },
  {
    exact: true,
    path: "/auth/facebook",
    component: lazy(() =>
      import("../pages/authentication/Login/FacebookCallback")
    ),
  },
  {
    exact: true,
    path: "/google_verify/:id",
    component: lazy(() =>
      import("../pages/authentication/Login/GoogleVerification")
    ),
  },
  {
    exact: true,
    path: "/facebook_verify/:id",
    component: lazy(() =>
      import("../pages/authentication/Login/FacebookVerification")
    ),
  },
];

export default routes;
