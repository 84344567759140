import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/forgot-password",
    component: lazy(() => import("../pages/authentication/ForgotPassword")),
  },
  {
    exact: true,
    path: "/confirm-email",
    component: lazy(() =>
      import("../pages/authentication/ForgotPassword/confirmEmail")
    ),
  },
  {
    exact: true,
    path: "/reset-password/:token",
    component: lazy(() =>
      import("../pages/authentication/ForgotPassword/resetPassword")
    ),
  },
  {
    exact: true,
    path: "/congratulation",
    component: lazy(() =>
      import("../pages/authentication/ForgotPassword/congratulationScreen")
    ),
  },
];

export default routes;
