import SignUpRoute from "./SignUpRoutes";
import LoginRoute from "./LoginRoutes";
import ForgotRoute from "./ForgotPasswordRoutes";
import HomePageRoute from "./HomePageRoutes";
import RegistrationRoute from "./RegistrationRoutes";
import FreelancerRoute from "./FreelancerRoutes";
import CustomerRoute from "./CustomerRoutes";
import SearchTalentRoute from "./TalentRoutes";
import PaymentRoute from "./PaymentRoutes";
import MyPackagesRoute from "./MyPackagesRoutes";
import MessageRoute from "./MessageRoutes";
import AdminRoute from "./AdminRoutes";

export default [
  ...AdminRoute,
  ...CustomerRoute,
  ...MessageRoute,
  ...MyPackagesRoute,
  ...PaymentRoute,
  ...SearchTalentRoute,
  ...FreelancerRoute,
  ...RegistrationRoute,
  ...ForgotRoute,
  ...HomePageRoute,
  ...LoginRoute,
  ...SignUpRoute,
];
