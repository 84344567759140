import { IS_WORK_SUBMITTED } from "../action-types";
import HTTP from "../../Http";

const initialState = {
  isLoggedIn: false,
  isWorkSubmitted: false,
};

const State = (state = initialState, { type, payload = null }) => {
  console.warn({ type });
  switch (type) {
    case "IS_WORK_SUBMITTED":
      return changeWorkSubmiited(state, payload);
    default:
      return state;
  }
};

function changeWorkSubmiited(state, payload) {
  state.isWorkSubmitted = payload;
  return {
    ...state,
    state,
  };
}

export default State;
