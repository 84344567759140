import React from "react";
import {
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import routes from "./routes";
import PublicRoute from "./Public";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import axios from "axios";

import { Url } from "../Helper";

// import ActiveJobs from '../../pages/Dashboard/ActiveJobs'
axios.defaults.baseURL = Url;
axios.defaults.headers.post["content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

function logoutAfterTimeout() {
  axios
            .post("/api/logout", { user_id: localStorage.getItem("user_id") })
            .then((res) => {
                if (res.status == 200) {
                    localStorage.removeItem("auth_token");
                    localStorage.removeItem("auth_name");
                    localStorage.removeItem("auth_type");
                    localStorage.clear();
                    // let path = "/";
                    window.location.reload();
                   
                }
            });
}

var timeoutID;

    function resetTimer() {
        clearTimeout(timeoutID);
        timeoutID = setTimeout(logoutAfterTimeout, 7200000); // 120,000 milliseconds = 120 seconds
    }

    // Attach the resetTimer() function to relevant events that indicate user activity
    // For example, you can use 'mousemove', 'keydown', and 'scroll'
    // Adjust the events as per your specific use case
    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('keydown', resetTimer);
    document.addEventListener('scroll', resetTimer);

    // Start the initial timer
    resetTimer();

console.warn({ routes });

const Routes = ({ assetPath }) => (
  <Router>
    <ScrollToTop />
    <Switch>
      {routes.map((route, i) => {
        return <PublicRoute key={i} {...route} />;
      })}
    </Switch>
  </Router>
);

export default Routes;
