import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/search-talent",
    component: lazy(() => import("../pages/Freelancer/Talent/SearchTalent")),
  },
  {
    exact: true,
    path: "/save-talent",
    component: lazy(() => import("../pages/Freelancer/Talent/SaveTalent")),
  },
  {
    exact: true,
    path: "/view-talent/:id",
    component: lazy(() => import("../pages/Freelancer/Talent/ViewTalent")),
  },
  {
    exact: true,
    path: "/customer-search-talent",
    component: lazy(() => import("../pages/Customer/Talent/SearchTalent")),
  },
  {
    exact: true,
    path: "/customer-save-talent",
    component: lazy(() => import("../pages/Customer/Talent/SaveTalent")),
  },
  {
    exact: true,
    path: "/customer-view-talent/:id",
    component: lazy(() => import("../pages/Customer/Talent/ViewTalent")),
  },
  {
    exact: true,
    path: "/package-checkout",
    component: lazy(() =>
      import("../pages/Customer/Talent/ViewTalent/packageCheckout")
    ),
  },
  {
    exact: true,
    path: "/submit-requirment",
    component: lazy(() =>
      import("../pages/Customer/Talent/ViewTalent/submitRequirment")
    ),
  },
];

export default routes;
