import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/admin",
    component: lazy(() => import("../pages/Admin/Dashboard")),
  },
  {
    exact: true,
    path: "/view-profile",
    component: lazy(() => import("../pages/Admin/Profile")),
  },
  {
    exact: true,
    path: "/all-jobs",
    component: lazy(() => import("../pages/Admin/AllJobs")),
  },
  {
    exact: true,
    path: "/view-job",
    component: lazy(() => import("../pages/Admin/AllJobs/ActiveJobs/Packages")),
  },
  {
    exact: true,
    path: "/talent",
    component: lazy(() => import("../pages/Admin/Talent/")),
  },
  {
    exact: true,
    path: "/view-talents",
    component: lazy(() => import("../pages/Admin/Talent/ViewTalent")),
  },
  {
    exact: true,
    path: "/settings",
    component: lazy(() => import("../pages/Admin/Settings")),
  },
  {
    exact: true,
    path: "/customers",
    component: lazy(() => import("../pages/Admin/Customers")),
  },
  {
    exact: true,
    path: "/view-customer",
    component: lazy(() => import("../pages/Admin/Customers/ViewCustomer")),
  },
  {
    exact: true,
    path: "/billing",
    component: lazy(() => import("../pages/Admin/Billing")),
  },
];

export default routes;
