import { IS_PROFILE_PIC } from "../action-types";
import HTTP from "../../Http";

const initialState = {
  isLoggedIn: false,
  isProfilePic: false,
};

const State = (state = initialState, { type, payload = null }) => {
  console.warn({ type });
  switch (type) {
    case "IS_PROFILE_PIC":
      return changeProilePic(state, payload);
    default:
      return state;
  }
};
function changeProilePic(state, payload) {
  state.isProfilePic = payload;
  return {
    ...state,
    state,
  };
}

export default State;
