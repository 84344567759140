import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/earnings",
    component: lazy(() => import("../pages/Freelancer/Payment/Balance")),
  },
  {
    exact: true,
    path: "/payment-method",
    component: lazy(() => import("../pages/Freelancer/Payment/PaymentMethod")),
  },
  {
    exact: true,
    path: "/add-payment-method",
    component: lazy(() =>
      import("../pages/Freelancer/Payment/PaymentMethod/addPayment")
    ),
  },
  {
    exact: true,
    path: "/customer-earnings",
    component: lazy(() => import("../pages/Customer/Payment/Balance")),
  },
  {
    exact: true,
    path: "/customer-payment-method",
    component: lazy(() => import("../pages/Customer/Payment/PaymentMethod")),
  },
  {
    exact: true,
    path: "/customer-add-payment-method",
    component: lazy(() =>
      import("../pages/Customer/Payment/PaymentMethod/addPayment")
    ),
  },
  {
    exact: true,
    path: "/add-payment-method-paypal",
    component: lazy(() =>
      import("../pages/Freelancer/Payment/PaymentMethod/Paypal")
    ),
  },
  {
    exact: true,
    path: "/manage-details/:id",
    component: lazy(() =>
      import("../pages/Freelancer/Payment/PaymentMethod/UpdateBankPayment")
    ),
  },
  {
    exact: true,
    path: "/manage-details-sec/:id",
    component: lazy(() =>
      import("../pages/Freelancer/Payment/PaymentMethod/UpdatPaypal")
    ),
  },
  {
    exact: true,
    path: "/withdrawalRequests",
    component: lazy(() =>
      import("../pages/Freelancer/Payment/Balance/WithdrawlRequests")
    ),
  },  
  {
    exact: true,
    path: "/completePayment/:id",
    component: lazy(() =>
      import("../pages/Customer/Payment/CompletePayment")
    ),
  },  
];

export default routes;
