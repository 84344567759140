import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/create-profile",
    component: lazy(() => import("../pages/authentication/FreelancerProfile")),
  },
  {
    exact: true,
    path: "/create-profile/thank-you",
    component: lazy(() =>
      import("../pages/authentication/FreelancerProfile/thankYouScreen")
    ),
  },
  {
    exact: true,
    path: "/dashboard",
    component: lazy(() => import("../pages/Freelancer/ProfileDashboard")),
  },

  {
    exact: true,
    path: "/inprogress-jobs",
    component: lazy(() => import("../pages/Freelancer/MyJobs/InprogessJobs")),
  },

  {
    exact: true,
    path: "/active-jobs",
    component: lazy(() => import("../pages/Freelancer/MyJobs/ActiveJobs")),
  },

  {
    exact: true,
    path: "/cancelJobPage/:id",
    component: lazy(() =>
      import("../pages/Freelancer/MyJobs/ActiveJobs/CancelJobPage")
    ),
  },

  {
    exact: true,
    path: "/submitted-jobs",
    component: lazy(() => import("../pages/Freelancer/MyJobs/SubmittedJobs")),
  },
  {
    exact: true,
    path: "/completed-jobs",
    component: lazy(() => import("../pages/Freelancer/MyJobs/CompletedJobs")),
  },
  {
    exact: true,
    path: "/cancelled-jobs",
    component: lazy(() => import("../pages/Freelancer/MyJobs/CancelledJobs")),
  },
  {
    exact: true,
    path: "/active-jobs/packages",
    component: lazy(() =>
      import("../pages/Freelancer/MyJobs/ActiveJobs/Packages")
    ),
  },
  {
    exact: true,
    path: "/freelancer-jobs/packages/:id",
    component: lazy(() =>
      import("../pages/Freelancer/MyJobs/ActiveJobs/Packages")
    ),
  },
  {
    exact: true,
    path: "/submitted-jobs/packages/:id",
    component: lazy(() =>
      import("../pages/Freelancer/MyJobs/SubmittedJobs/Packages")
    ),
  },
  {
    exact: true,
    path: "/completed-jobs/packages/:id",
    component: lazy(() =>
      import("../pages/Freelancer/MyJobs/CompletedJobs/Packages")
    ),
  },
  {
    exact: true,
    path: "/cancelled-jobs/packages",
    component: lazy(() =>
      import("../pages/Freelancer/MyJobs/CancelledJobs/Packages")
    ),
  },
  {
    exact: true,
    path: "/my-profile",
    component: lazy(() => import("../pages/Freelancer/Profile")),
  },

  {
    exact: true,
    path: "/user_agree",
    component: lazy(() => import("../pages/terms/user_agree")),
  },

  {
    exact: true,
    path: "/termofservice",
    component: lazy(() => import("../pages/terms/termofservice")),
  },

  {
    exact: true,
    path: "/privacy",
    component: lazy(() => import("../pages/terms/privacy")),
  },
  {
    exact: true,
    path: "/active_tab_content",
    component: lazy(() =>
      import("../pages/Freelancer/MyJobs/ActiveJobs/ActivitiesTabContent")
    ),
  },
  {
    exact: true,
    path: "/disputejobs",
    component: lazy(() => import("../pages/Freelancer/MyJobs/DisputeJobs")),
  },
  {
    exact: true,
    path: "/disputejobs/packages/:id",
    component: lazy(() =>
      import("../pages/Freelancer/MyJobs/DisputeJobs/Package")
    ),
  },
  {
    exact: true,
    path: "/support",
    component: lazy(() =>
      import("../pages/Support/Freelancer/SupportList")
    ),
  },
  {
    exact: true,
    path: "/new-request",
    component: lazy(() =>
      import("../pages/Support/Freelancer/NewSupport")
    ),
  },
  {
    exact: true,
    path: "/help",
    component: lazy(() =>
      import("../pages/Help/FreeHelp")
    ),
  },
  {
    exact: true,
    path: "/reports",
    component: lazy(() =>
      import("../pages/Reports/FreeReports")
    ),
  },
  {
    exact: true,
    path: "/pending-jobs",
    component: lazy(() =>
      import("../pages/Freelancer/MyJobs/PendingJobs")
    ),
  },
];

export default routes;
