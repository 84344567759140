import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/customer-create-profile",
    component: lazy(() => import("../pages/authentication/FreelancerProfile")),
  },
  {
    exact: true,
    path: "/customer-create-profile/thank-you",
    component: lazy(() =>
      import("../pages/authentication/FreelancerProfile/thankYouScreen")
    ),
  },
  {
    exact: true,
    path: "/dashboard-customer",
    component: lazy(() => import("../pages/Customer/ProfileDashboard")),
  },
  {
    exact: true,
    path: "/customer-inprogress-jobs",
    component: lazy(() => import("../pages/Customer/MyJobs/ActiveJobs")),
  },
  {
    exact: true,
    path: "/customer-pending-jobs",
    component: lazy(() => import("../pages/Customer/MyJobs/PendingJobs")),
  },
  {
    exact: true,
    path: "/customer-completed-jobs",
    component: lazy(() => import("../pages/Customer/MyJobs/CompletedJobs")),
  },
  {
    exact: true,
    path: "/customer-cancelled-jobs",
    component: lazy(() => import("../pages/Customer/MyJobs/CancelledJobs")),
  },
  {
    exact: true,
    path: "/customer-inprogress-jobs/packages",
    component: lazy(() =>
      import("../pages/Customer/MyJobs/ActiveJobs/Packages")
    ),
  },
  {
    exact: true,
    path: "/customer-pending-jobs/packages/:id",
    component: lazy(() =>
      import("../pages/Customer/MyJobs/PendingJobs/Packages")
    ),
  },
  {
    exact: true,
    path: "/customer-completed-jobs/packages",
    component: lazy(() =>
      import("../pages/Customer/MyJobs/CompletedJobs/Packages")
    ),
  },
  {
    exact: true,
    path: "/customer-cancelled-jobs/packages",
    component: lazy(() =>
      import("../pages/Customer/MyJobs/CancelledJobs/Packages")
    ),
  },
  {
    exact: true,
    path: "/customer-jobs/packages/:id",
    component: lazy(() =>
      import("../pages/Customer/MyJobs/ActiveJobs/Packages")
    ),
  },
  {
    exact: true,
    path: "/customer-my-profile",
    component: lazy(() => import("../pages/Customer/Profile")),
  },
  {
    exact: true,
    path: "/customer-messages",
    component: lazy(() => import("../pages/Customer/Messages")),
  },
  {
    exact: true,
    path: "/customer-review-jobs",
    component: lazy(() => import("../pages/Customer/MyJobs/ReviewJobs")),
  },
  {
    exact: true,
    path: "/customer-dispute-jobs",
    component: lazy(() => import("../pages/Customer/MyJobs/DisputeJobs")),
  },
  {
    exact: true,
    path: "/customer-dispute-jobs/packages/:id",
    component: lazy(() =>
      import("../pages/Customer/MyJobs/DisputeJobs/Package")
    ),
  },
  {
    exact: true,
    path: "/customer-cancel-Job-Page/:id",
    component: lazy(() =>
      import("../pages/Customer/MyJobs/ActiveJobs/CancelJobPage")
    ),
  },
  {
    exact: true,
    path: "/custContactSupport",
    component: lazy(() =>
      import("../pages/Support/Customer/CustSupportList")
    ),
  },
  {
    exact: true,
    path: "/custNewRequest",
    component: lazy(() =>
      import("../pages/Support/Customer/NewCustSupport")
    ),
  },
  {
    exact: true,
    path: "/cust-help",
    component: lazy(() =>
      import("../pages/Help/CustHelp")
    ),
  },
  {
    exact: true,
    path: "/cust-reports",
    component: lazy(() =>
      import("../pages/Reports/CustReports")
    ),
  },
  {
    exact: true,
    path: "/pending-payment/:id",
    component: lazy(() =>
      import("../pages/Customer/Payment/PendingPayment")
    ),
  },
];

export default routes;
