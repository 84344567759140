const initialState = {
  userId: "",
};

const State = (state = initialState, { type, payload = "" }) => {
  console.warn({ type });
  switch (type) {
    case "SENDER ID":
      return changeFreeUserId(state, payload);
    default:
      return state;
  }
};

function changeFreeUserId(state, payload) {
  state.id = payload;
  return {
    ...state,
    state,
  };
}

export default State;
