// const Url = 'https://plenty-keys-add-27-58-190-190.loca.lt'

const hostName = window.location.hostname;

const Url =
  hostName === "localhost"
    ? "http://localhost:8000"
    : hostName === "tnmarket.aleaspeaks.com"
    ? "https://apitnmarket.aleaspeaks.com"
    : "https://backendapi.talknative.co";
const messageUrl =
  window.location.hostname == "localhost"
    ? "http://localhost:8000/chatify/api/"
    : window.location.hostname == "https://apitnmarket.aleaspeaks.com"
    ? "https://apitnmarket.aleaspeaks.com/chatify/api/"
    : "https://backendapi.talknative.co/chatify/api/";

console.log("hostName12", window.location.hostname);

const pakageUrl = Url + "/uploads/package_photo/";
const profileUrl = Url + "/uploads/profile_image/";
const portfolioUrl = Url + "/uploads/portfolio_photo/";
const downloadImagePath = Url + "/api/show_image/";
export {
  Url,
  pakageUrl,
  profileUrl,
  portfolioUrl,
  messageUrl,
  downloadImagePath,
};
