import React from "react";
import ReactDOM from "react-dom";
import store from "./store";
import Routes from "./routes";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import "../src/assets/css/style.css";
import "../src/assets/css/responsive.css";
// import { authCheck } from './store/actions/user'

// store.dispatch(authCheck())
const assetPath = "";

ReactDOM.render(
  <Provider store={store}>
    <Routes assetPath={assetPath} />
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
