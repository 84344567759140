import { IS_PROFILE_PIC_CUST } from "../action-types";
import HTTP from "../../Http";

const initialState = {
  isLoggedIn: false,
  isProfilePicCust: false,
};

const State = (state = initialState, { type, payload = null }) => {
  console.warn({ type });
  switch (type) {
    case "IS_PROFILE_PIC_CUST":
      return changeProilePicCust(state, payload);
    default:
      return state;
  }
};
function changeProilePicCust(state, payload) {
  state.isProfilePicCust = payload;
  return {
    ...state,
    state,
  };
}

export default State;
