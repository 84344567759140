import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/registration",
    component: lazy(() => import("../pages/authentication/Registration")),
  },
  {
    exact: true,
    path: "/congratulations",
    component: lazy(() =>
      import("../pages/authentication/Registration/congratulationScreen")
    ),
  },
  {
    exact: true,
    path: "/verify/:id",
    component: lazy(() =>
      import("../pages/authentication/Registration/varify")
    ),
  },
  {
    exact: true,
    path: "/customer-verify/:id",
    component: lazy(() =>
      import("../pages/authentication/Registration/cust-varify")
    ),
  },
];

export default routes;
