import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/my-packages",
    component: lazy(() => import("../pages/Freelancer/MyPackages")),
  },
  {
    exact: true,
    path: "/view-package/:id",
    component: lazy(() => import("../pages/Freelancer/MyPackages/ViewPackage")),
  },
  {
    exact: true,
    path: "/add-package",
    component: lazy(() => import("../pages/Freelancer/MyPackages/AddPackage")),
  },
  {
    exact: true,
    path: "/my-packages-customer",
    component: lazy(() => import("../pages/Customer/MyPackages")),
  },
  {
    exact: true,
    path: "/view-package-customer/:id",
    component: lazy(() => import("../pages/Customer/MyPackages/ViewPackage")),
  },
  {
    exact: true,
    path: "/view-package-freelancer/:id",
    component: lazy(() =>
      import("../pages/Freelancer/Talent/ViewTalent/ViewPackage")
    ),
  },
  {
    exact: true,
    path: "/add-package-customer",
    component: lazy(() => import("../pages/Customer/MyPackages/AddPackage")),
  },
  {
    exact: true,
    path: "/updatePackage/:id",
    component: lazy(() =>
      import("../pages/Freelancer/MyPackages/updatePackage")
    ),
  },
];

export default routes;
